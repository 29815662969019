import React from 'react';
import { GatsbyBrowser, Script, ScriptStrategy } from 'gatsby';
import { BaseLayout } from './src/layouts/Base';

export const wrapPageElement = ({ element, props }) => (
  // props provide same data to Layout as Page element will get
  // including location, data, etc - you don't need to pass it
  <BaseLayout {...props}>
    <>
      {/* Element */}
      {element}
    </>
  </BaseLayout>
);
