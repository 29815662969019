import { Link } from 'gatsby';
import React, { FC, useState } from 'react';
import styled, { css } from 'styled-components';
import { getBackgroundColor } from '../utils/getBackgroundColor';
import { Crown } from './Crown';
import { Logo } from './Logo';
import { Typography } from './Typography';

const StlyedLogo = styled(Logo)`
  ${({ theme: { maxMedia } }) => css`
    @media (max-width: ${maxMedia.md}) {
      display: none;
    }
  `};
`;

const StlyedCrownLogo = styled(Crown)`
  ${({ theme: { media } }) => css`
    @media (min-width: ${media.md}) {
      display: none;
    }
  `};
`;

const StlyedHeader = styled.header`
  ${({ theme: { space } }) => css`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    ${getBackgroundColor('blue')}

    a {
      text-decoration: none;
    }
  `};
`;

const StlyedHeaderContent = styled.div`
  ${({ theme: { space, media, global } }) => css`
    width: 100%;
    max-width: 1128px;
    display: flex;
    gap: ${space.lg};
    justify-content: space-between;
    padding: ${space.sm} ${space.md};
    align-items: center;

    @media (min-width: ${media.lg}) {
      padding: ${space.md} 0;
    }
  `};
`;

export const Header: FC = () => (
  <StlyedHeader>
    <StlyedHeaderContent>
      <div>
        <Typography variant="h1" component="p">
          <Link to="/">The Borromei Bank</Link>
        </Typography>
        <Typography variant="lead" component="p">
        <Link to="/">Research Project</Link>
        </Typography>
      </div>
      <StlyedCrownLogo />
      <StlyedLogo />
    </StlyedHeaderContent>
  </StlyedHeader>
);
