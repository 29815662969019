import { createGlobalStyle } from 'styled-components';
import { getBackgroundColor } from '../utils/getBackgroundColor';
import { getResponsiveType } from '../utils/getResponsiveType';
import { colors, global, space, shadows } from './tokens';

export const GlobalStyle = createGlobalStyle`

    *, *::before, *::after {
        box-sizing: border-box;
        margin: 0;
        padding: 0;
        border: 0;
    }

    *:focus {
        outline: none; // We'll roll own own focus
    }

    *:focus-visible {
        outline: solid 4px ${colors.gold.default};
    }

    /* Chrome, Safari, Edge, Opera */
    input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
    }

    /* Firefox */
    input[type=number] {
        -moz-appearance: textfield;
    }

    html, body {
        height: 100%;
        max-width: 100vw;
        overflow-x: hidden;
    }

    body {
        -webkit-font-smoothing: antialiased;

        &.is-hidden {
            overflow: hidden;
        }
    }


    .wrapper {

    display: flex;
    flex-direction: column;
    min-height: 100vh;
    }
    .main {
        flex-grow: 1;
    }
    img, picture, video, canvas, svg {
        display: block;
        max-width: 100%;
    }

    input, button, textarea, select {
        font: inherit;
    }

    p, h1, h2, h3, h4, h5, h6 {
        overflow-wrap: break-word;
    }

    #root, #__next {
        isolation: isolate;
    }

    ul, ol {
        list-style: none;
    }

    a {
        color: currentColor;
    }

    a[href^='tel:'] {
        text-decoration: none;
    }

    address {
        font-style: normal;
    }

    button {
        cursor: pointer;
        background: none;
        color: currentColor;
    }

    // Selection

    ::selection {
        // background-color: ${colors.blueLight.default};
        // color: ${colors.blueLight.contrast};
    }

    // Body

    body {
        ${getResponsiveType('body')}
        background: ${colors.white.default};
        color: ${colors.blue.default};
        min-height: 100vh;
        width: 100%;
        min-width: 330px;
        accent-color: ${colors.blueLight.default};
    }

    // Tippy

    .tippy-popper {
        .tippy-tooltip {
            background: ${colors.blue.default};
            border-radius: ${global.borderRadius.default};
            padding: ${space.sm};
            max-width: 256px;
            box-shadow: ${shadows.default};

            p + p {
              margin-top: ${space.md};
            }
        }

        &[x-placement^=bottom] [x-arrow] {
            border-bottom: 7px solid ${colors.blue.default};
        }

        &[x-placement^=top] [x-arrow] {
            border-top: 7px solid ${colors.blue.default};
        }
    }


`;

export default GlobalStyle;
