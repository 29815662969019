import React, { FC, useState } from 'react';
import styled, { css } from 'styled-components';
import { getBackgroundColor } from '../utils/getBackgroundColor';
import { Crown } from './Crown';
import { Logo } from './Logo';
import { Panel } from './Panel';
import { Spacer } from './Spacer';
import { Typography } from './Typography';

const StyledFooter = styled.footer`
  display: flex;
  flex-direction: center;
  justify-content: center;
  ${getBackgroundColor('blue')}
`;

const StyledFooterContent = styled.div`
  ${({ theme: { media, space } }) => css`
    display: flex;
    flex-direction: column;
    width: 100%;
    max-width: 1128px;
    padding: ${space.md};
    gap: ${space.md};

    @media (min-width: ${media.lg}) {
      flex-direction: row;
      padding: ${space.lg} 0;

      svg {
        flex-shrink: 0;
        margin-left: auto;
      }
    }
  `};
`;
export const Footer: FC = () => (
  <StyledFooter>
    <StyledFooterContent>
      <Typography variant="bodySm" component="p">
        © Images from the ledgers and of the family coat of arms, copyright of the Borromeo-Arese family of Milan.{' '}
        <br />
        <strong>Images must not be be reproduced or distributed in any form without written permission.</strong>
      </Typography>
      <Crown />
    </StyledFooterContent>
  </StyledFooter>
);
