const layers = {
  subZero: -1,
  base: 0,
  filterPanel: 1,
  confirmationPanel: 99,
  editPanelBody: 100,
  editPanelCta: 101,
  datePicker: 99999,
  sticky: 999990,
  modalOverlay: 999999,
};

export default layers;

export type ThemeLayers = typeof layers;
