import { ThemeMediaKeys } from './media';

const fontFamily = {
  base: '"Source Sans Pro", sans-serif',
};

export const fontWeight = {
  regular: 400,
  bold: 700,
};

export interface TypeStyles {
  fontFamily?: string;
  fontWeight?: number;
  fontSize?: string;
  lineHeight?: string;
  textTransform?: string;
  fontStyle?: string;
  letterSpacing?: string;
  breakpoints?: [TypeBreakpoints];
}

interface TypeBreakpoints extends TypeStyles {
  breakpointSize: ThemeMediaKeys;
}
export interface TypeVariants {
  default: TypeStyles;
}
export type TypeVariantsOptions = keyof TypeVariants;

export type ThemeFonts = Record<string, TypeVariants>;

function createTypeMap<T extends { [name: string]: TypeVariants }>(cfg: T) {
  return cfg;
}

const bodyBase = {
  fontFamily: fontFamily.base,
  fontSize: '16px',
  lineHeight: '24px',
};

const bodySmBase = {
  fontFamily: fontFamily.base,
  fontSize: '14px',
  lineHeight: '18px',
};

const type = createTypeMap({
  h1: {
    default: {
      fontWeight: fontWeight.bold,
      fontFamily: fontFamily.base,
      fontSize: '26px',
      lineHeight: '32px',
      breakpoints: [
        {
          breakpointSize: 'md',
          fontSize: '32px',
          lineHeight: '40px',
        },
      ],
    },
  },
  h2: {
    default: {
      fontWeight: fontWeight.bold,
      fontFamily: fontFamily.base,
      fontSize: '22px',
      lineHeight: '32px',
      breakpoints: [
        {
          breakpointSize: 'md',
          fontSize: '24px',
          lineHeight: '32px',
        },
      ],
    },
  },
  lead: {
    default: {
      fontWeight: fontWeight.regular,
      fontFamily: fontFamily.base,
      fontSize: '22px',
      lineHeight: '32px',
      breakpoints: [
        {
          breakpointSize: 'md',
          fontSize: '24px',
          lineHeight: '32px',
        },
      ],
    },
  },
  body: {
    default: { ...bodyBase, fontWeight: fontWeight.regular },
  },
  bodyBold: {
    default: { ...bodyBase, fontWeight: fontWeight.bold },
  },
  bodyItalic: {
    default: { ...bodyBase, fontWeight: fontWeight.regular, fontStyle: 'italic' },
  },
  bodySm: {
    default: { ...bodySmBase, fontWeight: fontWeight.regular },
  },
  bodySmBold: {
    default: { ...bodySmBase, fontWeight: fontWeight.bold },
  },
  bodySmItalic: {
    default: { ...bodySmBase, fontWeight: fontWeight.regular, fontStyle: 'italic' },
  },
  caps: {
    default: { ...bodyBase, fontWeight: fontWeight.regular, textTransform: 'uppercase' },
  },
});

export default type;

export type ThemeFontsKeys = keyof typeof type;
