import React from 'react';
import { PageProps } from 'gatsby';
import { ThemeProvider } from 'styled-components';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import GlobalStyles from '../styles/GlobalStyles';
import theme from '../styles/tokens';
import { Footer } from '../components/Footer';
import { Header } from '../components/Header';
import '@fontsource/source-sans-pro/400.css';
import '@fontsource/source-sans-pro/400-italic.css';
import '@fontsource/source-sans-pro/700.css';
import 'swiper/css/bundle';

const queryClient = new QueryClient();

export const BaseLayout = ({ location, data, children, serverData }: PageProps) => (
  <>
    <QueryClientProvider client={queryClient}>
      <GlobalStyles />
      <ThemeProvider theme={theme}>
        <div className="wrapper">
          <Header />
          <main className="main">{children}</main>
          <Footer />
        </div>
      </ThemeProvider>
    </QueryClientProvider>
  </>
);
