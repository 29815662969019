import { colors } from './colors';

const borders = {
  default: `solid 2px ${colors.blue.default}`,
  blue: `solid 2px ${colors.blue.default}`,
  gold: `solid 2px ${colors.gold.default}`,
  error: `solid 2px ${colors.error.default}`,
  valid: `solid 2px ${colors.valid.default}`,
};

export default borders;

export type ThemeBorders = typeof borders;
export type ThemeBordersKeys = keyof typeof borders;
