const global = {
  borderRadius: {
    default: '3px',
  },
  maxWidth: '1128px',
};

export default global;
export type ThemeGlobal = typeof global;
export type ThemeGlobalKeys = keyof typeof global;
