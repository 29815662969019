import math from 'polished/lib/math/math';
import media, { ThemeMedia } from './media';

const maxMedia = Object.entries(media).reduce((accumulator, [key, value]) => {
  const newValue = { ...accumulator };
  newValue[key] = math(`${value} - 1`);
  return newValue;
}, {}) as ThemeMedia;

export default maxMedia;
