import React, { FC } from 'react';
import styled, { css } from 'styled-components';
import { getResponsiveType } from '../utils/getResponsiveType';
import { ThemeFontsKeys } from '../styles/tokens/typography';
import { ThemeColorsKeys } from '../styles/tokens/colors';

type TypographyComponents =
  | 'h1'
  | 'h2'
  | 'h3'
  | 'h4'
  | 'h5'
  | 'p'
  | 'span'
  | 'small'
  | 'strong'
  | 'legend'
  | 'button'
  | 'dt'
  | 'dd'
  | 'li'
  | 'address'
  | 'figcaption'
  | 'div'
  | undefined;

export interface TypographyProps {
  component?: TypographyComponents | any;
  variant: ThemeFontsKeys;
  color?: ThemeColorsKeys;
  visuallyHidden?: boolean;
}

interface TypographyStylesProps {
  component?: TypographyComponents | any;
  variant: ThemeFontsKeys;
  color?: ThemeColorsKeys;
  $visuallyHidden?: boolean;
}

const TypographyStyles = styled.p<TypographyStylesProps>`
  ${({ theme: { colors }, variant, color, $visuallyHidden }) => css`
    color: ${color ? colors[color].default : 'currentColor'};

    // Variants

    ${variant && getResponsiveType(variant)};

    // Visually hidden

    ${$visuallyHidden &&
    css`
      position: absolute;
      width: 1px;
      height: 1px;
      padding: 0;
      margin: -1px;
      overflow: hidden;
      clip: rect(0, 0, 0, 0);
      white-space: nowrap;
      border-width: 0;
    `}
  `};
`;

export const Typography: FC<React.PropsWithChildren<TypographyProps>> = ({
  children,
  component = 'p',
  variant,
  color,
  visuallyHidden,
  ...rest
}) => (
  <TypographyStyles as={component} variant={variant} color={color} $visuallyHidden={visuallyHidden} {...rest}>
    {children}
  </TypographyStyles>
);
