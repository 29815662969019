const media = {
  xxs: '0',
  xs: '340px',
  sm: '576px',
  md: '768px',
  lg: '992px',
  xl: '1200px',
};
export default media;
export type ThemeMedia = typeof media;
export type ThemeMediaKeys = keyof typeof media;
