const textColours = {
  default: '#393F49',
  light: '#FFFFFF',
};

export const colors = {
  blueDark: {
    default: '#092657',
    contrast: textColours.light,
  },
  blue: {
    default: '#0D3273',
    contrast: textColours.light,
  },

  blueLight: {
    default: '#C3CCDC',
    contrast: textColours.default,
  },
  blueLightest: {
    default: '#E7EBF1',
    contrast: textColours.default,
  },
  gold: {
    default: '#CEA600',
    contrast: textColours.light,
  },
  goldLight: {
    default: '#FAF6E5',
    contrast: textColours.default,
  },
  goldLightest: {
    default: '##FAF6E5',
    contrast: textColours.default,
  },
  grey: {
    default: '#393F49',
    contrast: textColours.light,
  },
  greyLight: {
    default: '#96A1B5',
    contrast: textColours.light,
  },

  white: {
    default: '#ffffff',
    contrast: textColours.default,
  },
  error: {
    default: '#E30613',
    contrast: textColours.light,
  },
  valid: {
    default: '#719949',
    contrast: '#FFFFFF',
  },
  transparent: {
    default: 'transparent',
    contrast: 'transparent',
  },
};

export default colors;

export type ThemeColors = typeof colors;
export type ThemeColorsKeys = keyof typeof colors;
